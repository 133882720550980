
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
    name: "error-test",
    setup() {
        const { state } = useStore();

        const error = ref("Error");
        onMounted(() => {
            error.value = `Error in mounted for ${
                state.user?.name || state.user?.email
            }`;
            throw new Error(error.value);
        });

        return {
            error,
            throwError(): void {
                throw new Error("test in button handler error from button");
            }
        };
    }
});
